import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Theme } from '../../types';
import { debounce } from '../../utils/tools';
import AddThemeItem from './AddThemeItem';
import OtherThemeItem from './OtherThemeItem';
import ThemeItem from './ThemeItem';

interface ThemesListProps {
  themes: Theme[];
  onRemoveTheme: (index: number, theme: Theme) => void;
  onUpdateTheme: (index: number, theme: Theme) => void;
  onAddTheme: VoidFunction;
  tagsCounter: { [themeName: string]: number };
  resultsCount?: number;
  nameInputRefs: React.MutableRefObject<(HTMLInputElement | null)[]>;
  focusLastAddedNameInput: VoidFunction;
}

const ThemesList: React.FC<ThemesListProps> = ({
  themes,
  onRemoveTheme,
  onUpdateTheme,
  onAddTheme,
  tagsCounter,
  resultsCount,
  nameInputRefs,
  focusLastAddedNameInput,
}) => {
  const prevLengthRef = useRef(themes.length);

  useEffect(() => {
    if (themes.length > prevLengthRef.current) {
      focusLastAddedNameInput();
    }
    prevLengthRef.current = themes.length;
    nameInputRefs.current = nameInputRefs.current.filter((ref) => ref !== null);
  }, [themes, focusLastAddedNameInput, nameInputRefs]);

  const gridContainerRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (!gridContainerRef.current) return;

    const handleScroll = debounce(() => {
      setScrollPosition({
        x: gridContainerRef.current?.scrollLeft ?? 0,
        y: gridContainerRef.current?.scrollTop ?? 0,
      });
    }, 100); // Debounce time of 100 milliseconds

    gridContainerRef.current.addEventListener('scroll', handleScroll);

    return () => {
      // TODO: checkout lint error: The ref value 'gridContainerRef.current' will likely have changed by the time this effect cleanup function runs. If this ref points to a node rendered by React, copy 'gridContainerRef.current' to a variable inside the effect, and use that variable in the cleanup function.
      gridContainerRef?.current?.removeEventListener('scroll', handleScroll);
    };
  }, [gridContainerRef]);

  const [scrolledToTop, setScrolledToTop] = useState(false);
  useEffect(() => {
    if (
      scrolledToTop ||
      !gridContainerRef.current ||
      !themes ||
      themes.length === 0
    )
      return;

    setScrolledToTop(true);

    setTimeout(() => {
      gridContainerRef?.current?.scrollTo(0, 0);
    }, 1);
  }, [gridContainerRef, themes, scrolledToTop]);

  return (
    <Grid ref={gridContainerRef}>
      {themes
        .sort(
          (a, b) =>
            (tagsCounter[b.id || b.localId!] || 0) -
            (tagsCounter[a.id || a.localId!] || 0)
        )
        .map((theme, i) => (
          <ThemeItem
            key={theme.id || theme.localId}
            theme={theme}
            onRemove={(v) => onRemoveTheme(i, v)}
            setValue={(v) => onUpdateTheme(i, v)}
            ref={(el) => (nameInputRefs.current[i] = el)}
            withPlaceholder={i === 0}
            tagSampleCounter={tagsCounter[theme.id || theme.localId!]}
            totalTagsCount={resultsCount}
            scrollOffset={scrollPosition.y}
          />
        ))}
      <AddThemeItem onClick={onAddTheme} />
      <OtherThemeItem
        tagSampleCounter={tagsCounter['Other']}
        totalTagsCount={resultsCount}
        scrollOffset={scrollPosition.y}
      />
    </Grid>
  );
};

export default ThemesList;

const Grid = styled.div`
  width: 899px;
  max-height: calc(100vh - 272px);
  overflow-y: scroll;
  justify-content: center;
  grid-template-columns: repeat(2, 430px);
  margin-top: 22px;
  display: grid;
  column-gap: 24px;
  row-gap: 16px;
`;
