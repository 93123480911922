import React from 'react';
import { Colors } from '../../theme/Colors';

interface CircledPlusIconProps {
  className?: string;
}

const CircledPlusIcon: React.FC<CircledPlusIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.5'
        y='0.5'
        width='23'
        height='23'
        rx='11.5'
        fill='transparent'
      />
      <rect
        x='0.5'
        y='0.5'
        width='23'
        height='23'
        rx='11.5'
        stroke={Colors.B30}
        strokeLinejoin='round'
        strokeDasharray='1 2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 6.16699C12.3452 6.16699 12.625 6.44681 12.625 6.79199V17.2087C12.625 17.5538 12.3452 17.8337 12 17.8337C11.6548 17.8337 11.375 17.5538 11.375 17.2087V6.79199C11.375 6.44681 11.6548 6.16699 12 6.16699Z'
        fill={Colors.B30}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.16602 12C6.16602 11.6548 6.44584 11.375 6.79102 11.375H17.2077C17.5529 11.375 17.8327 11.6548 17.8327 12C17.8327 12.3452 17.5529 12.625 17.2077 12.625H6.79102C6.44584 12.625 6.16602 12.3452 6.16602 12Z'
        fill={Colors.B30}
      />
    </svg>
  );
};

export default CircledPlusIcon;
