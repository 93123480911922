import { useEffect, useRef, useState } from 'react';

interface UsePeriodicCheckOptions {
  idleTime: number; // Time in seconds to consider user idle
  predicate: () => boolean; // Function to evaluate the second condition
  onAction: () => Promise<any>; // Function to call if both conditions are true
}

const usePeriodicCheck = ({
  idleTime = 10,
  predicate,
  onAction,
}: UsePeriodicCheckOptions): void => {
  const lastActivityTime = useRef(Date.now());
  const [isIdle, setIsIdle] = useState(false);
  const throttling = useRef(false);
  const activityThrottling = useRef(false);

  useEffect(() => {
    if (isIdle && predicate() && !throttling.current) {
      // Apply throttling to API call execution
      throttling.current = true;

      onAction().finally(() => {
        setTimeout(() => {
          throttling.current = false; // Allow next execution
        }, 5000); // Adjust throttling delay as needed
      });

      setIsIdle(false); // Reset idle state after execution
    }
  }, [isIdle, predicate, onAction]); // Removed throttling from dependencies

  useEffect(() => {
    const handleActivity = () => {
      if (!activityThrottling.current) {
        lastActivityTime.current = Date.now();
        setIsIdle(false);

        // Start throttling
        activityThrottling.current = true;
        setTimeout(() => {
          activityThrottling.current = false; // Reset throttling after time frame
        }, 2000); // Throttle duration in milliseconds
      }
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        setIsIdle(true); // Mark as idle if tab goes to the background
      } else {
        lastActivityTime.current = Date.now(); // Reset activity on focus
        setIsIdle(false);
      }
    };

    // Attach event listeners for user activity and tab visibility
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Periodic check interval
    const interval = setInterval(() => {
      const idleDuration = (Date.now() - lastActivityTime.current) / 1000; // Convert to seconds
      if (idleDuration >= idleTime || document.hidden) {
        setIsIdle(true);
      }
    }, 1000); // Check every second

    // Cleanup
    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      clearInterval(interval);
    };
  }, [idleTime, predicate, onAction]);
};

export default usePeriodicCheck;
