import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  createOrUpdateColumnDefinitionsApi,
  fetchProjectApi,
  getJobsDisplayApi,
  initJobApi,
  reAnalyzeQuestionApi,
  updateProjectApi,
} from '../api/pipelineApi';
import DownloadIcon from '../assets/download.svg';
import EditIcon from '../assets/edit.svg';
import SettingsSlidersIcon from '../assets/settings-sliders.svg';

import { downloadProjectActiveVersionsXlsxFile } from '../api/filesApi';
import SettingsIcon from '../assets/settings.svg';
import PageHeader from '../components/PageHeader';
import CenteredVerticalFlex from '../components/containers/CenteredVerticalFlex';
import HorizontalFlex from '../components/containers/HorizontalFlex';
import PageContainer from '../components/containers/PageContainer';
import Menu from '../components/menu/Menu';
import MenuButton from '../components/menu/MenuItemTextButtonWithIcon';
import ErrorModal from '../components/modal/ErrorModal';
import MessageModal from '../components/modal/MessageModal';
import H2 from '../components/newTextComponents/H2';
import P2 from '../components/newTextComponents/P2';
import P3 from '../components/newTextComponents/P3';
import ProjectImage from '../components/project/ProjectImage';
import ProjectRenameInputModal from '../components/project/ProjectRenameInputModal';
import QuestionsList from '../components/project/QuestionsList';
import LoadingModal from '../components/tagResults/LoadingModal';
import useOutsideClickHandler from '../hooks/useOutsideClickHandler';
import usePageVisibility from '../hooks/usePageVisibility';
import { Colors } from '../theme/Colors';
import { ColumnDefinitionDraft, JobDisplayData, JobStatus } from '../types';
import { GENERAL_ERROR_MESSAGE } from '../utils/constants';
import logger from '../utils/logger';
import { imagesArray } from '../utils/utils';
interface ProjectPageProps {}

const ProjectPage: React.FC<ProjectPageProps> = ({}) => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const isTabVisible = usePageVisibility();

  const queryClient = useQueryClient();

  if (!projectId) {
    throw new Error('projectId is required');
  }

  const [analyzeQuestionWarningData, setAnalyzeQuestionWarningData] = useState<
    JobDisplayData | undefined
  >();

  const [jobsDraft, setJobsDraft] = useState<JobDisplayData[] | undefined>();

  const { data: projectData, isPending: projectLoading } = useQuery<any, Error>(
    {
      queryKey: ['project', projectId],
      queryFn: () => fetchProjectApi(projectId!),
      retry: 3,
      refetchOnWindowFocus: false,
    }
  );
  useEffect(() => {
    if (projectData?.status === 'file_uploaded') {
      navigate(`/projects/${projectId}/create-project`);
    }
  }, [projectData, navigate, projectId]);

  const {
    data: jobsDisplayData,
    isPending: jobsDisplayLoading,
    isError: jobsDisplayIsError,
  } = useQuery<any, Error, JobDisplayData[]>({
    queryKey: ['jobsDisplay', projectId],
    queryFn: () => getJobsDisplayApi(projectId),
    retry: 5,
    refetchOnWindowFocus: true,
    refetchInterval: isTabVisible ? 5000 : false,
  });

  const {
    mutateAsync: initJobMutateAsync,
    // data: initJobData,
    isPending: initJobIsLoading,
    error: initJobError,
    reset: initJobReset,
  } = useMutation<any, Error, string>({
    mutationFn: (jobId) => initJobApi(projectId, jobId),
  });

  const initErrorMessage =
    initJobError?.message === 'Tag All limit exceeded' ? (
      <div>
        Oops! It looks like you don’t have enough credits to analyze this
        question. Please contact us at{' '}
        <a href='mailto:info@blix.ai'>info@blix.ai</a> to add more credits and
        continue your analysis.
      </div>
    ) : (
      initJobError?.message ?? GENERAL_ERROR_MESSAGE
    );

  const {
    mutateAsync: reAnalyzeQuestionMutateAsync,
    isPending: reAnalyzeQuestionIsLoading,
    error: reAnalyzeQuestionError,
    reset: reAnalyzeQuestionReset,
  } = useMutation<any, Error, string>({
    mutationFn: (jobId) => reAnalyzeQuestionApi(projectId, jobId),
  });

  const {
    mutateAsync: createOrUpdateColumnDefinitionsMutateAsync,
    // data: createOrUpdateColumnDefinitionsData,
    isPending: createOrUpdateColumnDefinitionsIsLoading,
    error: createOrUpdateColumnDefinitionsError,
    reset: createOrUpdateColumnDefinitionsReset,
  } = useMutation<any, Error, ColumnDefinitionDraft[]>({
    mutationFn: (cds) => createOrUpdateColumnDefinitionsApi(cds, projectId),
  });

  useEffect(() => {
    if (!jobsDisplayData) return;

    setJobsDraft(jobsDisplayData);
  }, [jobsDisplayData]);

  const analyze = async (jobDisplay: JobDisplayData) => {
    setAnalyzeQuestionWarningData(undefined);
    const res = await initJobMutateAsync(jobDisplay.id);
    const jobVersionId = res?.job_version?.id;
    const jobId = res?.job?.id;
    if (!jobVersionId || !jobId) {
      return alert('Unexcepted error, please try again later or contact admin');
    }
    navigate(`${jobId}/${jobVersionId}/setup`);
  };

  const onClickJobAction = async (job: JobDisplayData) => {
    if (!jobsDraft) {
      alert('Unexpected error, please try again later or contact admin');
      return;
    }

    await createOrUpdateColumnDefinitionsMutateAsync(
      jobsDraft as ColumnDefinitionDraft[]
    );

    if (job.status === JobStatus.CODEBOOK) {
      navigate(
        `/projects/${projectId}/${job.jobId}/${job.activeJobVersionId}/split-view-codebook-editor`
      );
    } else if (job.status === JobStatus.NOT_ANALYZED) {
      logger.info('Analyze Clicked');
      setAnalyzeQuestionWarningData(job);
    } else if (job.status === JobStatus.INITIAL) {
      navigate(`${job.jobId}/${job.activeJobVersionId}/setup`);
    } else if (job.status === JobStatus.PROCESSED) {
      navigate(
        `/projects/${projectId}/dashboard/${job.jobId}/${job.activeJobVersionId}`
      );
    } else if (job.status === JobStatus.ANALYZED) {
      logger.error(
        'Unexpected state, shouldnt be able to click in ANALYZED status'
      );
    }
  };

  const onReAnalyzeQuestion = async (job: JobDisplayData) => {
    if (![JobStatus.PROCESSED, JobStatus.FAILED].includes(job.status)) {
      alert("Can't reanalyze this question, contact admin for more info");
      return;
    }

    if (!job.jobId) {
      alert('Unexpected error, please try again later or contact admin');
      return;
    }

    const res = await reAnalyzeQuestionMutateAsync(job.jobId);

    navigate(
      `/projects/${projectId}/${res.job.id}/${res.jobVersion.id}/split-view-codebook-editor`
    );
  };

  const getXlsxFileName = () => {
    const fileName = `${projectData.name || 'Survey Analysis'}_coded_data.xlsx`;
    return fileName;
  };

  const {
    mutate: downloadFileMutate,
    isPending: downloadFileIsLoading,
    error: downloadFileError,
    reset: downloadFileReset,
  } = useMutation<any, Error>({
    mutationFn: () =>
      downloadProjectActiveVersionsXlsxFile(projectId, getXlsxFileName()),
  });

  const [showMenu, setShowMenu] = useState(false);

  const menuIconRef = useRef<HTMLImageElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  useOutsideClickHandler([menuIconRef, menuRef], () => {
    setShowMenu(false);
  });

  const onDownloadXlsxFileClick = () => {
    downloadFileMutate();
    setShowMenu(false);
  };

  const navigateToColumnMapper = () => {
    setShowMenu(false);
    navigate(`/projects/${projectId}/create-project`);
  };

  const {
    // mutate: updateProjectMutate,
    mutateAsync: updateProjectMutateAsync,
    isPending: updateProjectIsLoading,
    error: updateProjectError,
    reset: updateProjectReset,
  } = useMutation<any, Error, any>({
    mutationFn: (updateData) =>
      updateProjectApi(updateData.projectId, updateData.data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['project', projectId] });
    },
  });

  const [projectNameLocalState, setProjectNameLocalState] = useState(
    projectData?.name
  );

  const [showRenameModal, setShowRenameModal] = useState(false);
  const [missingProjectNameError, setMissingProjectNameError] = useState('');

  const renameProject = async () => {
    setShowMenu(false);
    const newName = projectNameLocalState;
    if (!newName || newName.length < 2) {
      setMissingProjectNameError(
        'Please provide a name for your project to proceed'
      );
      return;
    }

    await updateProjectMutateAsync({
      projectId: projectData.id,
      data: { name: newName },
    });

    setShowRenameModal(false);
  };

  const triggerRenameProject = () => {
    setProjectNameLocalState(projectData.name);
    setShowMenu(false);
    setShowRenameModal(true);
  };

  const getTagAllWarningMessage = () => {
    const count = analyzeQuestionWarningData?.rowCount;
    if (count) {
      return `You are about to analyze ${count} rows. ${count} credits will be consumed from your balance`;
    } else {
      return `You are about to tag all data items`;
    }
  };

  const isLoading = projectLoading || jobsDisplayLoading;
  if (isLoading)
    return <LoadingModal title='Fetching project..' hidden={!isLoading} />;

  return (
    <PageContainer>
      <PageHeader />
      <PageHeadersContainer>
        <ProjectImage src={imagesArray[projectData.id % imagesArray.length]} />
        <Header>Choose a question to analyze</Header>
        <SubHeaderContainer>
          <ProjectName>{projectData.name}</ProjectName>
          <Dot>·</Dot>
          {jobsDisplayData ? (
            <QuestionCounter>{`${jobsDisplayData.length} Questions`}</QuestionCounter>
          ) : null}
        </SubHeaderContainer>
        <MenuIcon
          ref={menuIconRef}
          src={SettingsIcon}
          onClick={(e) => {
            e.stopPropagation();
            setShowMenu((x) => !x);
          }}
          $clicked={showMenu}
        />
        <StyledMenu $visible={showMenu} ref={menuRef}>
          <MenuButton
            text='Export all analyzed'
            disabled={
              jobsDisplayData?.filter((jd) => jd.status === 'Processed')
                .length === 0
            }
            icon={<MenuItemIcon src={DownloadIcon} />}
            onClick={onDownloadXlsxFileClick}
          />
          <MenuButton
            text='Edit data mapping'
            icon={<MenuItemIcon src={SettingsSlidersIcon} />}
            onClick={navigateToColumnMapper}
          />
          <MenuButton
            text='Rename Project'
            icon={<MenuItemIcon src={EditIcon} />}
            onClick={triggerRenameProject}
          />
        </StyledMenu>
      </PageHeadersContainer>
      <ContentsContainer>
        <QuestionsList
          projectId={projectId}
          jobs={jobsDraft}
          setJobs={setJobsDraft}
          onClickJobAction={onClickJobAction}
          onReAnalyzeQuestion={onReAnalyzeQuestion}
        />
        {showRenameModal ? (
          <ProjectRenameInputModal
            show={showRenameModal}
            onClose={() => setShowRenameModal(false)}
            value={projectNameLocalState}
            onChange={setProjectNameLocalState}
            onRenameProjectClick={renameProject}
          />
        ) : null}
        {analyzeQuestionWarningData && (
          <MessageModal
            show={!!analyzeQuestionWarningData}
            onClose={() => setAnalyzeQuestionWarningData(undefined)}
            approveButtonText='Analyze'
            onApprove={() => analyze(analyzeQuestionWarningData)}
            title='Analyze Question'
            subTitle={getTagAllWarningMessage()}
          />
        )}
        <LoadingModal
          title='Setting up analysis...'
          hidden={!initJobIsLoading}
        />
        <ErrorModal
          show={!!initJobError}
          onClose={initJobReset}
          title={'Analyze Question Failed'}
          subTitle={initErrorMessage}
        />
        <LoadingModal
          title='Updating project..'
          hidden={!updateProjectIsLoading}
        />
        <ErrorModal
          show={!!updateProjectError}
          onClose={updateProjectReset}
          title='Failed to Update Project'
          subTitle={updateProjectError?.message ?? GENERAL_ERROR_MESSAGE}
        />
        <ErrorModal
          show={!!missingProjectNameError}
          onClose={() => setMissingProjectNameError('')}
          title='Project Name Required'
          subTitle={missingProjectNameError}
        />
        <LoadingModal
          title='Downloading Excel file'
          hidden={!downloadFileIsLoading}
        />
        <ErrorModal
          show={!!downloadFileError}
          onClose={downloadFileReset}
          title='Unexpected Error'
          subTitle={downloadFileError?.message ?? GENERAL_ERROR_MESSAGE}
        />
        <LoadingModal
          title='Loading analysis tasks...'
          hidden={!jobsDisplayLoading}
        />
        <ErrorModal
          show={!!jobsDisplayIsError}
          onClose={() => {}}
          title={'Fetch Data Error'}
          subTitle={GENERAL_ERROR_MESSAGE}
        />
        <LoadingModal
          title='Setting up question for re analyze...'
          hidden={!reAnalyzeQuestionIsLoading}
        />
        <ErrorModal
          show={!!reAnalyzeQuestionError}
          onClose={reAnalyzeQuestionReset}
          title={'Re Analyze Error'}
          subTitle={reAnalyzeQuestionError?.message ?? GENERAL_ERROR_MESSAGE}
        />
        <LoadingModal
          title='Updating analysis tasks changes...'
          hidden={!createOrUpdateColumnDefinitionsIsLoading}
        />
        <ErrorModal
          show={!!createOrUpdateColumnDefinitionsError}
          onClose={createOrUpdateColumnDefinitionsReset}
          title={'Update Project Error'}
          subTitle={
            createOrUpdateColumnDefinitionsError?.message ||
            GENERAL_ERROR_MESSAGE
          }
        />
      </ContentsContainer>
    </PageContainer>
  );
};

export default ProjectPage;

const ContentsContainer = styled(CenteredVerticalFlex)`
  justify-content: center;
  margin-top: 48px;
  margin-bottom: 120px;
`;

const Dot = styled(P3)`
  color: ${Colors.B60};
`;

const Header = styled(H2)`
  margin: 8px 0 4px 0;
`;

const PageHeadersContainer = styled(CenteredVerticalFlex)`
  position: relative;
  width: 1170px;
  margin: 20px 0;
`;

const ProjectName = styled(P2)`
  color: ${Colors.P100};
  font-weight: 500;
`;

const QuestionCounter = styled(P3)`
  color: ${Colors.B60};
  /* font-weight: 500; */
`;

const SubHeaderContainer = styled(HorizontalFlex)`
  gap: 10px;
`;

const MenuItemIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const MenuIcon = styled.img<{ $clicked: boolean }>`
  position: absolute;
  bottom: -16px;
  right: 0;
  border: 1px solid #dddddd;
  border-radius: 8px;
  height: 32px;
  width: 32px;
  z-index: 1;
  cursor: pointer;

  &:hover {
    ${({ $clicked }) =>
      !$clicked &&
      `
    background-color: ${Colors.GRAY_20};
  `}
  }

  ${({ $clicked }) =>
    $clicked &&
    `
    background-color: ${Colors.GRAY_40};
  `}
`;

const StyledMenu = styled(Menu)`
  right: 0;
  top: 130px;
`;
