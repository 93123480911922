import { useState } from 'react';

import { useEffect } from 'react';
import { ManualEdit, TagChange, TaggedUserContent, Theme } from '../types';
import logger from '../utils/logger';
import {
  areThemeIdsEqual,
  getUserContentAddedTagsLocalAndPersistedThemeIds,
  shouldDisplayManualOrPersistedOther,
} from '../utils/theme-utils';

const useFilters = (
  tagPageData: TaggedUserContent[],
  tagChangesMap: Record<string, TagChange[]>,
  manualEditsMap: Record<string, ManualEdit[]>,
  themesDraft: Theme[]
) => {
  const [filters, setFilters] = useState<Theme[]>([]);
  const [filteredData, setFilteredData] = useState([] as TaggedUserContent[]);

  useEffect(() => {
    if (!tagPageData) return;

    if (filters.length === 0) {
      setFilteredData(tagPageData as unknown as TaggedUserContent[]);
      return;
    }

    if (
      filters.some((filter) => filter.name === 'Other') &&
      filters.length > 1
    ) {
      // This is an efficiency optimization - since this scenario isn't valid; Other tag is exclusive
      setFilteredData([]);
      return;
    }

    const filtered = (tagPageData as unknown as TaggedUserContent[]).filter(
      (userContent) => {
        const addedThemes = getUserContentAddedTagsLocalAndPersistedThemeIds(
          userContent,
          manualEditsMap[userContent.id],
          tagChangesMap[userContent.id]
        );

        let otherFilterConditionApplied = true;

        if (filters.some((filter) => filter.name === 'Other')) {
          const { shouldDisplayManualOther, shouldDisplayPersistedOther } =
            shouldDisplayManualOrPersistedOther(
              userContent,
              themesDraft,
              manualEditsMap[userContent.id],
              tagChangesMap[userContent.id]
            );

          logger.info(
            `hasPersistedOther: content ${userContent.text}
            hasPersistedOther ${shouldDisplayPersistedOther}
            hasManualOther: ${shouldDisplayManualOther}`
          );

          otherFilterConditionApplied =
            shouldDisplayManualOther || shouldDisplayPersistedOther;
        }

        const filtersExcludingOtherContainedInTags = filters
          .filter((filter) => filter.name !== 'Other')
          .every((filter) =>
            addedThemes.some((t) => areThemeIdsEqual(t, filter))
          );

        return (
          filtersExcludingOtherContainedInTags && otherFilterConditionApplied
        );
      }
    );
    setFilteredData(filtered);
  }, [tagPageData, filters, tagChangesMap, manualEditsMap, themesDraft]);

  return { filteredData, filters, setFilters };
};

export default useFilters;
