import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import Separator from '../Seperator';
import ButtonWithActive from '../buttons/StyledButtonWithActive';
import Tooltip from '../buttons/Tooltip';
import CenteredHorizontalFlex from '../containers/CenteredHorizontalFlex';
import AddIcon from '../icons/AddIcon';
import DownloadIcon from '../icons/DownloadIcon';
import H2 from '../newTextComponents/H2';

interface CodebookControlBarProps {
  onClickDownloadCodebook: VoidFunction;
  onClickAddTheme: VoidFunction;
  themeCount: number;
}

const CodebookControlBar: React.FC<CodebookControlBarProps> = ({
  onClickDownloadCodebook,
  onClickAddTheme,
  themeCount,
}) => {
  return (
    <Container>
      <Separator />
      <Content>
        <H2>Themes ({themeCount || 0})</H2>
        <Buttons>
          <Tooltip toolTipText='Add a New Theme'>
            <AddButtonWithActive
              icon={<StyledAddIcon />}
              onClick={onClickAddTheme}
            />
          </Tooltip>
          <Tooltip toolTipText='Download Codebook CSV File'>
            <DownloadButtonWithActive
              icon={<StyledDownloadIcon />}
              onClick={onClickDownloadCodebook}
            />
          </Tooltip>
        </Buttons>
      </Content>
      <Separator />
    </Container>
  );
};

export default CodebookControlBar;

const Container = styled.div``;
const Content = styled(CenteredHorizontalFlex)`
  justify-content: space-between;
  margin: 8px 0;
`;
const Buttons = styled(CenteredHorizontalFlex)``;

const CommonIconStlye = `
  width: 32px;
  height: 32px;
  border-radius: 8px;
`;

const StyledDownloadIcon = styled(DownloadIcon)`
  ${CommonIconStlye};
`;

const StyledAddIcon = styled(AddIcon)`
  ${CommonIconStlye};
`;

const StyledButtonWithActive = styled(ButtonWithActive)`
  > svg {
    border: 1px solid ${Colors.B20};
  }
  padding: 0 12px;
`;

const DownloadButtonWithActive = styled(StyledButtonWithActive)`
  &:active > svg path {
    stroke: ${Colors.WHITE};
  }
  &:active > svg rect {
    stroke: transparent;
  }
`;

const AddButtonWithActive = styled(StyledButtonWithActive)`
  &:active > svg path {
    fill: ${Colors.WHITE};
  }
  &:active > svg rect {
    stroke: transparent;
  }
`;
