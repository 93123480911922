import { PropsWithChildren, useState } from 'react';
import styled from 'styled-components';
import Title from '../Title';
import PrimaryButton from '../buttons/PrimaryButton';
import CenteredVerticalFlex from '../containers/CenteredVerticalFlex';
import Modal from '../modal/Modal';

export interface OnCloseFunctionTypeParams {
  prompt_example_string?: string;
}

type OnCloseFunctionType = (params: OnCloseFunctionTypeParams) => void;
interface CodebookAdvancedSettingsModalProps {
  onClose: OnCloseFunctionType;
  hidden?: boolean;
  existingTagExampleText?: string;
}

const CodebookAdvancedSettingsModal: React.FC<
  PropsWithChildren<CodebookAdvancedSettingsModalProps>
> = ({ onClose, hidden, existingTagExampleText }) => {
  const [tagExampleText] = useState<string | undefined>(existingTagExampleText);

  const closeSettingsModal = () => {
    onClose({
      prompt_example_string: tagExampleText,
    });
  };

  return (
    <Modal onClose={closeSettingsModal} hidden={hidden}>
      <Container>
        <HorizonalContainer>
          <VerticalContainer>
            <StyledTitle>Advanced settings</StyledTitle>
          </VerticalContainer>
        </HorizonalContainer>
        <PrimaryButton type='button' label='OK' onClick={closeSettingsModal} />
      </Container>
    </Modal>
  );
};

export default CodebookAdvancedSettingsModal;

const Container = styled(CenteredVerticalFlex)``;

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px;
`;

const HorizonalContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 36px;
`;
