import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { DashboardThemeData } from '../../types';
import HorizontalGraph from './HorizontalGraph';
import ThemeDetails from './ThemeDetails';

interface SectionProps {
  dashboardThemesData: DashboardThemeData[];
  respondentsCount: number;
  layoutDirection: 'LTR' | 'RTL';
  userContentsLayoutDirection: 'LTR' | 'RTL';
  shouldTranslate: boolean;
}

const Section: React.FC<PropsWithChildren<SectionProps>> = ({
  dashboardThemesData,
  respondentsCount,
  layoutDirection,
  userContentsLayoutDirection,
  shouldTranslate,
}) => {
  const histogram = dashboardThemesData.map((theme) => ({
    theme: theme.name,
    count: theme.userContents.length,
  }));

  const theme_details_rows = dashboardThemesData
    .slice(0, 10)
    .map((themeData) => {
      const tagsCount = themeData.userContents.length;

      const percentage = tagsCount
        ? Math.floor((tagsCount / respondentsCount) * 100)
        : 0;

      const citations = themeData.userContents
        .slice(0, 10)
        .map((a) =>
          shouldTranslate && a.translatedText ? a.translatedText : a.text
        );

      return (
        <ThemeDetails
          key={themeData.name}
          themeName={themeData.name}
          percentage={percentage}
          section_summary={themeData.summary}
          citations={citations}
          layoutDirection={layoutDirection}
          userContentsLayoutDirection={userContentsLayoutDirection}
        />
      );
    });

  return (
    <Container>
      <HorizontalGraph
        histogram={histogram}
        respondentsCount={respondentsCount}
      />
      {theme_details_rows}
    </Container>
  );
};

export default Section;

const Container = styled.div`
  margin-bottom: 20px;
`;
