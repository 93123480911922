import React from 'react';
import styled from 'styled-components';
import { Theme } from '../../../types';
import logger from '../../../utils/logger';
import { escapeRegExp } from '../../../utils/utils';
import Card from './Card';
import EmptyResults from './EmptyResults';
import Row from './Row';

interface SearchResultsProps {
  themes: Theme[];
  inputValue: string;
  onSelectTheme: (theme: Theme) => void;
  highlightedRowIndex?: number;
  codebookChangesEnabled?: boolean;
}

const testMatch = (value: string, testAgainst: string) => {
  try {
    return new RegExp(escapeRegExp(value), 'i').test(testAgainst);
  } catch (error) {
    logger.error(`[testMatch] new RegExp failed`, error, {
      value,
      testAgainst,
    });
    return false;
  }
};
const SearchResults: React.FC<SearchResultsProps> = ({
  themes,
  inputValue,
  onSelectTheme,
  highlightedRowIndex,
  codebookChangesEnabled = true,
}) => {
  return (
    <Container>
      {themes.length > 0 ? (
        <Card>
          {themes.map((theme, index) => (
            <Row
              label={theme.name}
              key={theme.name + index}
              selected={testMatch(theme.name, inputValue)}
              onClick={() => onSelectTheme(theme)}
              highlightText={inputValue}
              highlightRow={highlightedRowIndex === index}
            >
              {theme.name}
            </Row>
          ))}
        </Card>
      ) : (
        <EmptyResults codebookChangesEnabled={codebookChangesEnabled} />
      )}
    </Container>
  );
};

export default SearchResults;

const Container = styled.div``;
