import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import {
  ColumnDefinitionDashboardResponse,
  DashboardThemeData,
  UserContentData,
} from '../../types';
import { mapObjectToLowerCaseKeys } from '../../utils/utils';
import Label from '../Label';
import CenteredHorizontalFlex from '../containers/CenteredHorizontalFlex';
import SelectInput from '../form/select/SelectInput';

interface CohortsFilterProps {
  columnDefinitions: ColumnDefinitionDashboardResponse[];
  dashboardThemesData: DashboardThemeData[];
  setMetaDataFilter: (
    filter:
      | {
          filterType: string;
          filterByValue: string;
        }
      | undefined
  ) => void;
  metaDataFilter?: {
    filterType: string;
    filterByValue: string;
  };
}

const CohortsFilter: React.FC<CohortsFilterProps> = ({
  columnDefinitions,
  dashboardThemesData,
  setMetaDataFilter,
  metaDataFilter,
}) => {
  const [filterType, setFilterType] = useState<string>('owner');
  const [filterValues, setFilterValues] = useState<string[] | undefined>();
  const [filterByValue, setFilterByValue] = useState<string>('');

  useEffect(() => {
    if (!filterType || !filterByValue) {
      setMetaDataFilter(undefined);
      return;
    }
  }, [filterType, filterByValue, setMetaDataFilter]);

  useEffect(() => {
    if (!dashboardThemesData) return;

    if (!filterType && !filterByValue) {
      return;
    }

    if (!filterValues) {
      const allMetadataTypes = dashboardThemesData
        .flatMap((theme: DashboardThemeData) => theme.userContents)
        .map((uc: UserContentData) => uc.metadata);
      const uniqueMetadataValues: string[] = Array.from(
        new Set(
          allMetadataTypes
            // .map((a: any) => JSON.parse(a))
            .map(mapObjectToLowerCaseKeys)
            .map((a: any) => a[filterType])
        )
      );
      setFilterValues(uniqueMetadataValues);
    }

    if (
      metaDataFilter?.filterType !== filterType ||
      metaDataFilter?.filterByValue !== filterByValue
    ) {
      setMetaDataFilter({
        filterType,
        filterByValue,
      });
    }
  }, [
    filterType,
    dashboardThemesData,
    filterByValue,
    setMetaDataFilter,
    filterValues,
    metaDataFilter,
  ]);

  const handleSelectFilterType = (event: any) => {
    setFilterType(event.target.value);
  };

  const handleSelectFilterValue = (value: string) => {
    setFilterByValue(value);
  };

  return (
    <Container>
      <StyledLabel>Filter by owner:</StyledLabel>
      <select
        style={{ display: 'none' }}
        value={filterType}
        onChange={handleSelectFilterType}
      >
        <option disabled value={''}>
          Please select metadata to filter by
        </option>
        {columnDefinitions.map((metadataType: any, index: number) => (
          <option key={index} value={metadataType.title}>
            {metadataType.title}
          </option>
        ))}
      </select>
      {filterValues ? (
        <Select
          label=''
          value={filterByValue}
          onChange={handleSelectFilterValue}
          options={[
            {
              label: 'All',
              value: '',
            },
          ].concat(
            filterValues.map((filter: string) => ({
              label: filter,
              value: filter,
            }))
          )}
          cardStyle={{ maxHeight: 400, overflowY: 'scroll' }}
        />
      ) : null}
    </Container>
  );
};

export default CohortsFilter;

const Container = styled(CenteredHorizontalFlex)`
  margin-bottom: 16px;
  margin-left: 50px;
`;

const StyledLabel = styled(Label)`
  text-align: left;
  font-size: 16px;
  /* color: ${Colors.PRIMARY}; */
`;

const Select = styled(SelectInput)`
  width: 90px;
`;
