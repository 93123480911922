import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { fetchDashboardApi } from '../api/pipelineApi';
import {
  DashboardData,
  DashboardThemeData,
  ManualEdit,
  TagChange,
  TagContent,
  ThemeResponse,
  UserContentData,
} from '../types';
import logger from '../utils/logger';
import {
  computeThemeIdToUserContentsAddedByTagChangesMap,
  extractUntaggedUserContents,
} from '../utils/tag-changes-utils';
import { isActiveTagChange } from '../utils/theme-utils';
import { mapObjectToLowerCaseKeys } from '../utils/utils';

const getSystemTaggedContentsNotRemovedByTagChanges = (
  theme: ThemeResponse,
  tagContents: TagContent[],
  tagChangesMap: Record<number, TagChange[]>,
  userContentsMap: Record<number, UserContentData>
) => {
  const filteredContents = tagContents
    .filter((tc: any) => tc.theme_id === theme.id)
    .filter(
      (tc) =>
        !tagChangesMap[tc.user_content_id]?.some(
          (tagChange) =>
            tagChange.themeId === tc.theme_id &&
            tagChange.action === 'remove' &&
            isActiveTagChange(tagChange)
        )
    )
    .filter((tc: TagContent) => !!userContentsMap[tc.user_content_id])
    .map((tc: TagContent) => userContentsMap[tc.user_content_id]);

  logger.info(
    `getSystemTaggedContentsNotRemovedByTagChanges - theme: ${theme.name} - filteredContents: ${filteredContents}`
  );

  return filteredContents;
};

const useDashboardData = (
  jobVersionId: string,
  tagChangesMap: Record<number, TagChange[]>,
  manualEditsMap: Record<number, ManualEdit[]>
) => {
  const [showCohorts, setShowCohorts] = useState(false);
  const [respondentsCount, setRespondentsCount] = useState<
    number | undefined
  >();

  const {
    data: dashboardData,
    isPending: dashboardIsLoading,
    error: dashboardError,
  } = useQuery<DashboardData, Error>({
    queryKey: ['dashboard', jobVersionId],
    queryFn: () => fetchDashboardApi(jobVersionId!),
    refetchOnWindowFocus: false,
  });

  const [metaDataFilter, setMetaDataFilter] = useState<
    | {
        filterType: string;
        filterByValue: string;
      }
    | undefined
  >();

  const [nonEmptyUserContents, setNonEmptyUserContents] = useState<
    UserContentData[]
  >([]);

  useEffect(() => {
    setRespondentsCount(nonEmptyUserContents.length);
  }, [nonEmptyUserContents]);

  useEffect(() => {
    // TODO: this could cause an issue sync we invalidate the query after sync
    if (dashboardData) {
      setNonEmptyUserContents(dashboardData.non_empty_user_contents);
    }
  }, [dashboardData]);

  const [dashboardThemesData, setDashboardThemesData] = useState<
    DashboardThemeData[] | undefined
  >();

  useEffect(() => {
    if (dashboardData) {
      setShowCohorts([12, 2].includes(dashboardData.organization_id));
    }
  }, [dashboardData]);

  useEffect(() => {
    if (dashboardData && Object.keys(nonEmptyUserContents).length > 0) {
      const userContentsMap = nonEmptyUserContents.reduce((acc, uc) => {
        acc[uc.id] = uc;
        return acc;
      }, {} as Record<number, UserContentData>);

      const themeIdToAddedContentsMap =
        computeThemeIdToUserContentsAddedByTagChangesMap(
          tagChangesMap,
          manualEditsMap,
          dashboardData.tag_contents,
          dashboardData.themes,
          nonEmptyUserContents
        );

      const data = dashboardData.themes
        // .filter((t) => t.name !== 'Other')
        .map((theme: ThemeResponse) => ({
          ...theme,
          userContents: getSystemTaggedContentsNotRemovedByTagChanges(
            theme,
            dashboardData.tag_contents,
            tagChangesMap,
            userContentsMap
          ).concat(themeIdToAddedContentsMap.get(theme.id) || []),
        }));

      const untaggedUserContents = extractUntaggedUserContents(
        tagChangesMap,
        dashboardData.tag_contents,
        userContentsMap
      );

      let otherTheme = data.find((t) => t.name === 'Other');
      if (!otherTheme) {
        logger.error(
          'unexpected scenario: system Other theme not found, adding local Other theme'
        );
        otherTheme = {
          name: 'Other',
          userContents: [],
          id: -1,
          summary: '',
        };
        data.push(otherTheme);
      }

      otherTheme.userContents = otherTheme.userContents.filter(
        (uc) => !tagChangesMap[uc.id]?.some((tc) => tc.action === 'add')
      );

      if (untaggedUserContents.length > 0) {
        otherTheme.userContents =
          otherTheme.userContents.concat(untaggedUserContents);
      }

      data.sort((a, b) => b.userContents.length - a.userContents.length);

      setDashboardThemesData(data);
    }
  }, [dashboardData, tagChangesMap, manualEditsMap, nonEmptyUserContents]);

  useEffect(() => {
    if (!dashboardData) return;

    if (
      !metaDataFilter ||
      !metaDataFilter.filterType ||
      !metaDataFilter.filterByValue
    ) {
      setRespondentsCount(dashboardData.non_empty_user_contents.length);
      setNonEmptyUserContents(dashboardData.non_empty_user_contents);
      return;
    }

    const filteredUserContents = dashboardData.non_empty_user_contents.filter(
      (uc: UserContentData) =>
        mapObjectToLowerCaseKeys(uc.metadata)[metaDataFilter.filterType] ==
        metaDataFilter.filterByValue
    );

    setNonEmptyUserContents(filteredUserContents);
  }, [
    setRespondentsCount,
    setNonEmptyUserContents,
    metaDataFilter,
    dashboardData,
  ]);

  return {
    dashboardData,
    dashboardIsLoading,
    dashboardError,
    showCohorts,
    dashboardThemesData,
    metaDataFilter,
    setMetaDataFilter,
    respondentsCount,
  };
};

export default useDashboardData;
