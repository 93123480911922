import { MouseEventHandler } from 'react';

export type ButtonOnClickHandler =
  | MouseEventHandler<HTMLButtonElement>
  | undefined;

export interface FileObject {
  Key: string;
  LastModified: string;
  Size: number;
}

export interface FormValues {
  themes_file?: File;
  data_file?: File;
  company_name: string;
  question: string;
  content_column: string;
  analysis_depth: number;
  interaction_type?: string;
  interaction_type_plural?: string;
  interaction_source?: string;
  long_theme_description_length?: string;
  company_metadata?: string;
  minimum_filtered_content_char_length?: number;
  sub_themes_creation_threshold?: number;
  minimium_required_count_of_related_interactions?: number;
  sample_size?: number;
  theme_gen_algorithm_id?: number;
  number_of_themes?: number;
  with_sentiment?: boolean;
}

export interface TagDataFormValues {
  themes_file?: File;
  data_file?: File;
  company_name: string;
  content_column: string;
  interaction_type?: string;
  interaction_type_plural?: string;
  interaction_source?: string;
}

export interface MultiTagDataFormValues {
  themes_file?: File;
  data_file?: File;
  company_name: string;
  company_metadata?: string;
  question: string;
  prompt_example_string: string;
  content_column: string;
  with_sentiment: boolean;
  with_other_bucket: boolean;
  interaction_type?: string;
  interaction_type_plural?: string;
  interaction_source?: string;
  model: string;
}

export type CreateProjectResponse = {
  id: string;
  job_id: string;
  job_version_id: string;
  name: string;
};

export type Theme = {
  code_id?: number;
  id?: number;
  localId?: number;
  name: string;
  instructions?: string;
};

export type Tag = {
  themeId: number;
  themeName: string;
};

export type ManualEdit = {
  id: number;
  type: 'add' | 'remove';
  themeId: number;
  userContentId: number;
  jobVersionId: string;
  lastEdited: string;
  createdAt: string;
};

export type TaggedUserContent = {
  id: number;
  text: string;
  tags: Tag[];
  translatedText?: string;
  sourceLanguageISOCode?: string;
};

export type UserResponseEntry = {
  content: string;
  metadata: { [key: string]: string };
};

export enum ColumnType {
  OpenEnded = 'open_ended',
  Metadata = 'metadata',
  ClosedEnded = 'closed_ended',
  BrandAwareness = 'brand_awareness',
}

export enum JobVersionStatus {
  INITIAL = 'Initial',
  CODEBOOK = 'Codebook',
  PROCESSING = 'Processing',
  ANALYZED = 'Analyzed',
  PROCESSED = 'Processed',
  FAILED = 'Failed',
}

export enum JobStatus {
  NOT_ANALYZED = 'Not Analyzed',
  INITIAL = 'Initial',
  CODEBOOK = 'Codebook',
  PROCESSING = 'Processing',
  ANALYZED = 'Analyzed',
  PROCESSED = 'Processed',
  FAILED = 'Failed',
}

export type JobDisplayData = {
  id: string; // -> column_defition_id
  columnIndex: number;
  columnType: ColumnType;
  columnHeader: string;
  preview?: string;
  userDefinedTitle?: string;
  isExcluded?: boolean;
  questionId?: string;
  createdAt?: string;
  lastEdited?: string;
  status: JobStatus;
  jobId?: string;
  activeJobVersionId?: string;
  rowCount?: number;
};

export type JobVersionData = {
  id: number;
  isActive: boolean;
  jobId: string;
  createdAt: string;
  status: JobVersionStatus;
};

export type JobData = {
  translations_status?: {
    [languageCode: string]: 'in_progress' | 'completed' | 'failed';
  };
};

export type ColumnDefinitionDraft = {
  id?: string;
  columnIndex: number;
  columnType: ColumnType;
  columnHeader: string;
  preview?: string;
  userDefinedTitle?: string;
  isExcluded?: boolean;
  questionId?: string;
  rowCount?: number;
};

export interface CreateColumnDefinition {
  projectId: number;
  columnIndex: number;
  columnType: ColumnType;
  columnHeader: string;
  preview?: string;
  userDefinedTitle?: string;
  isExcluded?: boolean;
  rowCount?: number;
}

export interface ProjectData {
  id: number;
  job_id: number;
  job_version_id: number;
  image: string;
  name: string;
  old_status?: 'Initial' | 'Codebook' | 'Processing' | 'Processed' | 'Failed'; // Analyzed status shouldn't be an option since it was introduced after the new project status structure
  status?:
    | 'project_created'
    | 'file_uploaded'
    | 'data_columns_defined'
    | 'setup_completed';
  questions_counter: number;
  created_at: string;
}

export interface UpdateColumnDefinition {
  id: number;
  columnIndex?: number;
  columnType?: ColumnType;
  columnHeader?: string;
  preview?: string;
  userDefinedTitle?: string;
  isExcluded?: boolean;
  rowCount?: number;
}

export interface CreateBatchColumnDefinitionBody {
  columns: (UpdateColumnDefinition | CreateColumnDefinition)[];
}

export interface JobDisplay {
  questionId?: string;
  jobId?: string;
  activeJobVersionId?: string;
  columnHeader: string;
  preview: string;
  status:
    | 'Not Analyzed'
    | 'Initial'
    | 'Codebook'
    | 'Analyzed'
    | 'Processed'
    | 'Processing'
    | 'Failed';
}

export type StringKeyObject = { [key: string]: string };

export interface TagChange {
  id: number;
  action: 'add' | 'remove';
  userContentId: number;
  themeId?: number;
  themeLocalId?: number;
  status: 'pending' | 'syncing' | 'synced';
  createdAt: number;
}

export type TagContent = {
  content: string;
  meta: string;
  theme_id: number;
  theme_name: string;
  user_content_id: number;
};

export type ThemeResponse = {
  id: number;
  name: string;
  summary: string;
};

export interface ColumnDefinitionDashboardResponse {
  column_header: string;
  column_index: number;
  column_type: string;
  created_at: string;
  id: number;
  is_excluded: boolean;
  last_edited: string;
  preview: string;
  project_id: number;
  question_id: string | null;
  row_count: number;
  user_defined_title: string | null;
}
export interface DashboardData {
  non_empty_user_contents: UserContentData[];
  codebook_language: string;
  column_definitions: ColumnDefinitionDashboardResponse[];
  dashboard_logo_url: string;
  dashboard_title: string;
  organization_id: number;
  respondents_count: number;
  tag_contents: TagContent[];
  themes: ThemeResponse[];
  user_contents_layout_direction: 'LTR' | 'RTL';
}

export type UserContentData = {
  id: number;
  text: string;
  translatedText?: string;
  sourceLanguageISOCode?: string;
  metadata: { [key: string]: string };
};

export type DashboardThemeData = {
  id: number;
  name: string;
  summary: string;
  userContents: UserContentData[];
};
