// @ts-nocheck
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useNavigationType, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  downloadDashboardFile,
  downloadProjectActiveVersionsXlsxFile,
} from '../api/filesApi';
import {
  UpdateJobVersionRequestBody,
  fetchProjectApi,
  fetchThemesApi,
  getFirstJobVersionForProjectApi,
  getJobVersionsForJobApi,
  getNonEmptyUserContentCount,
  reAnalyzeQuestionApi,
  tagPageApi,
  updateJobVersionApi,
} from '../api/pipelineApi';
import CursorIcon from '../assets/cursor.png';
import DownloadIcon from '../assets/download.svg';
import PortraitDrawingIcon from '../assets/drawing.svg';
import NotebookWithPencilIcon from '../assets/notebook_with_pencil.svg';
import SpreadSheetMenuIcon from '../assets/spreadsheet.svg';
import ThreeDotsMenuIcon from '../assets/three_dots_menu.svg';
import PageHeader from '../components/PageHeader';
import ToggleInputWithLabel from '../components/ToggleInputWithLabel';
import RestoreVersionMenu from '../components/business-flows/restore-version/RestoreVersionMenu';
import useRestoreVersion from '../components/business-flows/restore-version/useRestoreVersion';
import BackButtonWithActive from '../components/buttons/BackButtonWithActive';
import StyledBackIcon from '../components/buttons/StyledBackIcon';
import HorizontalFlex from '../components/containers/HorizontalFlex';
import VerticalFlex from '../components/containers/VerticalFlex';
import CohortsFilter from '../components/dashboard/CohortsFilter';
import Header from '../components/dashboard/DashboardHeader';
import Section from '../components/dashboard/Section';
import Menu from '../components/menu/Menu';
import MenuIcon from '../components/menu/MenuIcon';
import MenuItemIcon from '../components/menu/MenuItemIcon';
import MenuButton from '../components/menu/MenuItemTextButtonWithIcon';
import ErrorModal from '../components/modal/ErrorModal';
import AutoFocusedInputModal from '../components/project/AutoFocusedInputModal';
import LoadingModal from '../components/tagResults/LoadingModal';
import useDashboardData from '../hooks/useDashboardData';
import useFilters from '../hooks/useFilters';
import useIsRestoreVersionEnabled from '../hooks/useIsRestoreVersionEnabled';
import useManualTagManager from '../hooks/useManualTagManager';
import useOutsideClickHandler from '../hooks/useOutsideClickHandler';
import useTranslation from '../hooks/useTranslation';
import { Colors } from '../theme/Colors';
import { JobVersionData } from '../types';
import { GENERAL_ERROR_MESSAGE } from '../utils/constants';
import { downloadThemesFileUtil } from '../utils/csv-utils';
import logger from '../utils/logger';

interface ReviewProjectPageProps {}

const ReviewProjectPage: React.FC<ReviewProjectPageProps> = () => {
  const { projectId, jobId, jobVersionId } = useParams();
  const navigationType = useNavigationType();

  const navigate = useNavigate();

  if (!projectId || !jobVersionId || !jobId) {
    throw new Error('projectId and jobVersionId are required');
  }

  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showUploadLogoModal, setShowUploadLogoModal] = useState(false);
  const [dashboardTitleLocalState, setDashboardTitleLocalState] = useState('');
  const [logoLocalState, setLogoLocalState] = useState('');
  const [showGetThemesErrorModal, setShowGetThemesErrorModal] = useState(false);
  const [
    deprecatedDownloadDashboardError,
    setDeprecatedDownloadDashboardError,
  ] = useState('');
  const [showMenu, setShowMenu] = useState(false);
  const [withRandomSampleOrder] = useState(false);
  const queryClient = useQueryClient();

  const { data: projectData, isPending: projectLoading } = useQuery<any, Error>(
    {
      queryKey: ['project', projectId],
      queryFn: () => fetchProjectApi(projectId!),
      retry: 3,
      refetchOnWindowFocus: false,
    }
  );

  const { data: nonEmptyUserContentCountData } = useQuery<any, Error>({
    queryKey: ['nonEmptyUserContentCount', jobId],
    queryFn: () => getNonEmptyUserContentCount(jobId!),
    retry: 5,
    refetchOnWindowFocus: false,
  });

  const {
    mutate: updateJobVersionMutate,
    isPending: updateJobVersionIsLoading,
    error: updateJobVersionError,
    reset: updateJobVersionReset,
  } = useMutation<any, Error, UpdateJobVersionRequestBody>({
    mutationFn: (data) => updateJobVersionApi(jobVersionId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['dashboard', jobVersionId] });
    },
  });

  // depracated - serves old projects with a single job version per project
  const { data: jobVersionData } = useQuery<any, Error>({
    queryKey: ['jobConfigurationForProject', projectId],
    queryFn: () => getFirstJobVersionForProjectApi(projectId!),
    refetchOnWindowFocus: false,
  });

  const { data: jobVersionsData } = useQuery<JobVersionData[], Error>({
    queryKey: ['versionsDataForJob', jobId, jobVersionId],
    queryFn: () => getJobVersionsForJobApi(jobId!),
    refetchOnWindowFocus: true,
  });

  const {
    restoreVersion,
    restoreVersionIsLoading,
    restoreVersionError,
    restoreVersionReset,
  } = useRestoreVersion(projectId, jobId, jobVersionId, setShowMenu);

  useEffect(() => {
    if (
      navigationType === 'POP' &&
      jobVersionsData &&
      !restoreVersionIsLoading
    ) {
      // User navigated via back or forward button
      const version = jobVersionsData?.find(
        (v) => v.id === parseInt(jobVersionId)
      );
      if (version && !version.isActive) {
        restoreVersion(version);
      }
    }
  }, [
    jobVersionId,
    restoreVersion,
    jobVersionsData,
    navigationType,
    restoreVersionIsLoading,
  ]);

  const getXlsxFileName = () => {
    const fileName = `${projectData.name || 'Survey Analysis'}_coded_data.xlsx`;

    return fileName;
  };

  const isRestoreVersionEnabled = useIsRestoreVersionEnabled(
    jobId,
    jobVersionId
  );

  const {
    mutate: deprecatedDownloadFileMutate,
    isPending: deprecatedDownloadFileIsLoading,
    error: deprecatedDownloadFileError,
    reset: deprecatedDownloadFileReset,
  } = useMutation<any, Error>({
    mutationFn: () =>
      downloadDashboardFile(
        jobVersionData.id,
        projectData.output_file_name || jobVersionData.output_file_name,
        getXlsxFileName()
      ),
  });

  const {
    mutateAsync: reAnalyzeQuestionMutateAsync,
    isPending: reAnalyzeQuestionIsLoading,
    error: reAnalyzeQuestionError,
    reset: reAnalyzeQuestionReset,
  } = useMutation<any, Error, string>({
    mutationFn: (jobId) => reAnalyzeQuestionApi(projectId, jobId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['jobDisplay', projectId, jobId],
      });
    },
  });

  const {
    mutate: downloadFileMutate,
    isPending: downloadFileIsLoading,
    error: downloadFileError,
    reset: downloadFileReset,
  } = useMutation<any, Error>({
    mutationFn: () =>
      downloadProjectActiveVersionsXlsxFile(projectId!, getXlsxFileName()),
  });

  const jobVersionIdForThemes = projectData?.old_status
    ? jobVersionData?.id
    : jobVersionId;

  const {
    data: fetchThemesData,
    error: fetchThemesError,
    refetch: fetchThemesRefetch,
    isFetching: fetchThemesLoading,
  } = useQuery<any, Error>({
    queryKey: ['themes', jobVersionIdForThemes],
    queryFn: () => fetchThemesApi(jobVersionIdForThemes!),
    retry: 3,
    refetchOnWindowFocus: false,
    // enabled: false,
  });

  const {
    data: tagPageData,
    error: tagPageError,
    fetchNextPage,
    hasNextPage,
    isFetching: tagPageIsFetching,
    isLoading: tagPageIsLoading,
    isFetchingNextPage,
    refetch: tagPageRefetch,
  } = useInfiniteQuery({
    queryKey: ['tag_page', jobVersionId, withRandomSampleOrder],
    queryFn: async ({ pageParam, signal }) => {
      logger.info('tag_page queryFn pageParam: ', pageParam);
      return tagPageApi(
        jobVersionId!,
        pageParam,
        withRandomSampleOrder,
        signal
      );
    },
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    initialPageParam: 0,
    enabled: !!fetchThemesData && fetchThemesData.length > 0,
    refetchOnWindowFocus: false,
    select: (data) => {
      const res = data.pages.reduce((acc, page) => [...acc, ...page.data], []);
      return res;
    },
  });

  const {
    tagChangesMap,
    userContentUnderEdit,
    syncLoading,
    syncError,
    resetLoadingAndError,
    addTag,
    removeTag,
    setUserContentUnderEdit,
    syncCodebookAndTagChanges,
    manualEditsMap,
    manualEditsLoading,
  } = useManualTagManager(jobVersionId);

  const {
    dashboardData,
    dashboardIsLoading,
    dashboardError,
    showCohorts,
    dashboardThemesData,
    metaDataFilter,
    setMetaDataFilter,
    respondentsCount,
  } = useDashboardData(jobVersionId, tagChangesMap, manualEditsMap);

  const {
    toggleTranslate,
    presentTranslation,
    resetTranslationError,
    translateToggleChecked,
    translationIsLoading,
    translationError,
  } = useTranslation(jobVersionId, jobId);

  const layoutDirection =
    dashboardData?.codebook_language === 'Hebrew' ? 'RTL' : 'LTR';

  useEffect(() => {
    setDashboardTitleLocalState(dashboardData?.dashboard_title || '');
  }, [dashboardData]);

  useEffect(() => {
    if (fetchThemesError) {
      logger.error('fetchThemesError: ', fetchThemesError);
      setShowGetThemesErrorModal(true);
    }
  }, [fetchThemesError]);

  const hasTagChangesOrCodebookDirty = useCallback(() => {
    logger.info('hasTagChangesOrCodebookDirty - started');

    const hasActiveTagChanges = Object.values(tagChangesMap).some(
      (value) =>
        value.filter((x) => ['pending', 'syncing'].includes(x.status)).length >
        0
    );

    logger.info(
      'hasTagChangesOrCodebookDirty - hasActiveTagChanges: ',
      hasActiveTagChanges
    );

    return hasActiveTagChanges;
  }, [tagChangesMap]);

  // usePeriodicCheck({
  //   idleTime: 200, // Consider idle after 10 seconds
  //   predicate: hasTagChangesOrCodebookDirty,
  //   onAction: () =>
  //     syncCodebookAndTagChanges(jobVersionId, fetchThemesData ?? []),
  // });

  const { filteredData, filters, setFilters } = useFilters(
    tagPageData,
    tagChangesMap,
    manualEditsMap,
    fetchThemesData ?? []
  );

  const onScrollToEndOfTagResults = () => {
    if (hasNextPage && !tagPageIsFetching) {
      logger.info('onScrollToEndOfTagResults: calling fetchNextPage');
      fetchNextPage();
    }
  };

  const onRenameProjectClick = () => {
    setShowMenu(false);
    updateJobVersionMutate({ dashboard_title: dashboardTitleLocalState });
    setShowRenameModal(false);
  };

  const onUploadLogoClick = () => {
    setShowMenu(false);
    updateJobVersionMutate({
      dashboard_logo_url: logoLocalState,
    });
    setShowUploadLogoModal(false);
  };

  const menuIconRef = useRef<HTMLImageElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  useOutsideClickHandler([menuIconRef, menuRef], () => {
    setShowMenu(false);
  });

  const onDownloadXlsxFileClick = () => {
    if (projectData.old_status) {
      if (
        !jobVersionData?.id ||
        (!projectData.output_file_name && !jobVersionData.output_file_name)
      ) {
        logger.error('jobVersionData is missing');
        setDeprecatedDownloadDashboardError(
          `The dashboard file is missing for project id ${projectId}, please contact admin for help`
        );
        return;
      }

      deprecatedDownloadFileMutate();
    } else {
      downloadFileMutate();
    }
    setShowMenu(false);
  };

  const onDownloadCodebookClick = async () => {
    setShowMenu(false);
    const fetchThemesData = await fetchThemesRefetch();
    const fileName = `${
      dashboardData?.dashboard_title || 'Survey Analysis'
    }_codebook`;
    if (fetchThemesData.data) {
      downloadThemesFileUtil(fetchThemesData.data, `${fileName}`);
    } else {
      alert('no themes were found, please contact admin');
    }
  };

  const clickBackButton = () => {
    if (projectData?.old_status) {
      navigate(`/projects/`);
    } else {
      navigate(`/projects/${projectId}`);
    }
  };

  const onReAnalyzeQuestion = async () => {
    const res = await reAnalyzeQuestionMutateAsync(jobId);

    navigate(
      `/projects/${projectId}/${res.job.id}/${res.jobVersion.id}/split-view-codebook-editor`
    );
  };

  if (
    dashboardIsLoading ||
    projectLoading ||
    restoreVersionIsLoading ||
    !jobVersionsData ||
    !dashboardData ||
    !dashboardThemesData
  )
    return (
      <LoadingModal title='Loading Dashboard...' hidden={!dashboardIsLoading} />
    );
  if (dashboardError)
    return (
      <ErrorModal
        show={!!dashboardError}
        onClose={() => navigate('/projects')}
        title='Dashboard Loading Error'
        subTitle={dashboardError?.message || GENERAL_ERROR_MESSAGE}
      />
    );

  return (
    <Container>
      <Container>
        <PageHeader />
        <Box
          sx={{
            width: '100%',
            maxWidth: '1000px',
            margin: 'auto',
            paddingTop: '20px',
            paddingBottom: '20px',
            position: 'relative',
          }}
        >
          <BackButton icon={<StyledBackIcon />} onClick={clickBackButton} />
          <Paper
            elevation={3}
            sx={{
              paddingLeft: '30px',
              paddingRight: '30px',
              paddingBottom: '16px',
              position: 'relative',
            }}
          >
            <MenuIcon
              ref={menuIconRef}
              src={ThreeDotsMenuIcon}
              onClick={(e) => {
                e.stopPropagation();
                setShowMenu((x) => !x);
              }}
              $clicked={showMenu}
            />
            {showMenu && (
              <Menu $visible={showMenu} ref={menuRef}>
                <MenuButton
                  text='Change Dashboard Title'
                  icon={<MenuItemIcon src={CursorIcon} />}
                  onClick={() => setShowRenameModal(true)}
                />
                <MenuButton
                  text='Change Dashboard Logo'
                  icon={<MenuItemIcon src={PortraitDrawingIcon} />}
                  onClick={() => setShowUploadLogoModal(true)}
                />
                <MenuButton
                  text='Export data to Excel'
                  icon={<MenuItemIcon src={SpreadSheetMenuIcon} />}
                  onClick={onDownloadXlsxFileClick}
                />
                <MenuButton
                  text='Download Codebook'
                  icon={<MenuItemIcon src={DownloadIcon} />}
                  onClick={onDownloadCodebookClick}
                />
                <MenuButton
                  text='Re Analyze Question'
                  icon={<MenuItemIcon src={NotebookWithPencilIcon} />}
                  onClick={onReAnalyzeQuestion}
                  textStyle={{ fontSize: 14 }}
                />
                {isRestoreVersionEnabled && (
                  <RestoreVersionMenu
                    projectId={projectId}
                    jobId={jobId}
                    activeJobVersionId={jobVersionId}
                    setShowMenu={setShowMenu}
                  />
                )}
              </Menu>
            )}
            <Header
              title={dashboardData.dashboard_title || 'Survey Analysis'}
              logoUrl={dashboardData.dashboard_logo_url}
            />
            {showCohorts ? (
              <CohortsFilter
                columnDefinitions={dashboardData.column_definitions}
                dashboardThemesData={dashboardThemesData}
                setMetaDataFilter={setMetaDataFilter}
                metaDataFilter={metaDataFilter}
              />
            ) : null}
            <Section
              dashboardThemesData={dashboardThemesData}
              respondentsCount={
                respondentsCount ?? dashboardData.respondents_count
              }
              layoutDirection={layoutDirection}
              userContentsLayoutDirection={
                dashboardData.user_contents_layout_direction
              }
              shouldTranslate={presentTranslation}
            />
          </Paper>
        </Box>
        {showRenameModal ? (
          <AutoFocusedInputModal
            maxLength={50}
            show={showRenameModal}
            onClose={() => setShowRenameModal(false)}
            value={dashboardTitleLocalState}
            label='Choose New Dashboard Title'
            placeholder='Acme inc.'
            buttonLabel='Rename'
            onChangeInputValue={setDashboardTitleLocalState}
            onClickCTA={onRenameProjectClick}
          />
        ) : null}
        {showUploadLogoModal ? (
          <AutoFocusedInputModal
            show={showUploadLogoModal}
            onClose={() => setShowUploadLogoModal(false)}
            value={logoLocalState}
            label='Upload New Logo Link'
            placeholder='https://picsum.photos/id/237/200/300'
            buttonLabel='OK'
            onChangeInputValue={setLogoLocalState}
            onClickCTA={onUploadLogoClick}
          />
        ) : null}
      </Container>
      {/* <TagResultsContainer>
        <TranslateToggle
          label='Translate Verbatim'
          onChange={toggleTranslate}
          checked={translateToggleChecked}
          loading={translationIsLoading}
          icon={<MenuItemIcon src={TranslateIcon} />}
        />
        <H3
          onClick={() =>
            syncCodebookAndTagChanges(jobVersionId, fetchThemesData ?? [])
          }
        >
          Sync Changes
        </H3>
        <TagResultsWithScrollToEnd
          filters={filters}
          setFilters={setFilters}
          themesDraft={fetchThemesData ?? []}
          results={filteredData}
          nonEmptyUserContentCount={nonEmptyUserContentCountData?.count}
          onScrollToEnd={onScrollToEndOfTagResults}
          isFetchingNextPage={isFetchingNextPage}
          pageIsLoading={tagPageIsLoading}
          codebookIsDirty={false}
          createTheme={() => 0}
          addSelectedTextToTheme={() => {}}
          codebookLanguage={
            dashboardData?.codebook_language as 'Hebrew' | 'English'
          }
          isCodebookInFirstVersion={false}
          translationEnabled={presentTranslation}
          tagChangesMap={tagChangesMap}
          userContentUnderEdit={userContentUnderEdit}
          syncLoading={syncLoading && false}
          syncError={syncError}
          resetLoadingAndError={resetLoadingAndError}
          addTag={addTag}
          removeTag={removeTag}
          setUserContentUnderEdit={setUserContentUnderEdit}
          manualEditsMap={manualEditsMap}
          manualEditsLoading={manualEditsLoading}
          withHighlightText={false}
          codebookChangesEnabled={false}
        />
      </TagResultsContainer> */}
      <LoadingModal
        title='Updating dashboard'
        hidden={!updateJobVersionIsLoading}
      />
      <LoadingModal
        title='Downloading Excel file'
        hidden={!downloadFileIsLoading}
      />
      <LoadingModal title='Getting Codebook' hidden={!fetchThemesLoading} />
      <ErrorModal
        show={!!updateJobVersionError}
        onClose={updateJobVersionReset}
        title='Unexpected Error'
        subTitle={updateJobVersionError?.message ?? GENERAL_ERROR_MESSAGE}
      />
      <LoadingModal
        title='Setting up question for re analyze...'
        hidden={!reAnalyzeQuestionIsLoading}
      />
      <ErrorModal
        show={!!reAnalyzeQuestionError}
        onClose={reAnalyzeQuestionReset}
        title={'Re Analyze Error'}
        subTitle={reAnalyzeQuestionError?.message ?? GENERAL_ERROR_MESSAGE}
      />
      <LoadingModal
        title='Downloading Excel file'
        hidden={!deprecatedDownloadFileIsLoading}
      />
      <ErrorModal
        show={!!deprecatedDownloadFileError}
        onClose={deprecatedDownloadFileReset}
        title='Unexpected Error'
        subTitle={deprecatedDownloadFileError?.message ?? GENERAL_ERROR_MESSAGE}
      />
      <ErrorModal
        show={!!restoreVersionError}
        onClose={restoreVersionReset}
        title='Unexpected Error'
        subTitle={restoreVersionError?.message ?? GENERAL_ERROR_MESSAGE}
      />
      <ErrorModal
        show={!!deprecatedDownloadDashboardError}
        onClose={() => setDeprecatedDownloadDashboardError('')}
        title='Unexpected Error'
        subTitle={deprecatedDownloadDashboardError}
      />
      <ErrorModal
        show={!!showGetThemesErrorModal}
        onClose={() => setShowGetThemesErrorModal(false)}
        title='Unexpected Error'
        subTitle={GENERAL_ERROR_MESSAGE}
      />
      <ErrorModal
        show={!!downloadFileError}
        onClose={downloadFileReset}
        title='Unexpected Error'
        subTitle={downloadFileError?.message ?? GENERAL_ERROR_MESSAGE}
      />
      <ErrorModal
        show={!!tagPageError}
        onClose={tagPageRefetch}
        title={'Tag Sample Error'}
        subTitle={tagPageError?.message || GENERAL_ERROR_MESSAGE}
      />
      <ErrorModal
        show={!!translationError}
        onClose={resetTranslationError}
        title={'Translation Error'}
        subTitle={
          'Oops! It looks like we encountered an issue translating your data. Please try again. If the problem persists, contact our support team for assistance.'
        }
      />
    </Container>
  );
};

export default ReviewProjectPage;

const BackButton = styled(BackButtonWithActive)`
  position: absolute;
  z-index: 100;
  left: -110px;
  top: 30px;
`;

const Container = styled(VerticalFlex)`
  background-color: ${Colors.WHITE};
`;

const TopContainer = styled(HorizontalFlex)`
  flex: 1;
  gap: 32px;
`;

const TagResultsContainer = styled(VerticalFlex)`
  /* flex-grow: 1; */
  /* max-width: calc(100vw - 995px); */
`;

const TranslateToggle = styled(ToggleInputWithLabel)`
  padding: 16px 24px;
`;
