import { useEffect, useState } from 'react';
import { ManualEdit, TagChange, TaggedUserContent, Theme } from '../types';
import {
  getUserContentAddedTagsLocalAndPersistedThemeIds,
  shouldDisplayManualOrPersistedOther,
} from '../utils/theme-utils';

const useTagsCounter = (
  tagPageData: TaggedUserContent[],
  tagChangesMap: Record<number, TagChange[]>,
  manualEditsMap: Record<number, ManualEdit[]>,
  themesDraft: Theme[]
) => {
  const [tagsCounter, setTagsCounter] = useState(
    {} as { [themeId: string]: number }
  );

  useEffect(() => {
    if (!tagPageData) return;

    const themeCounter = (tagPageData as TaggedUserContent[]).reduce(
      (counter, item) => {
        const addedThemesIds = getUserContentAddedTagsLocalAndPersistedThemeIds(
          item,
          manualEditsMap[item.id],
          tagChangesMap[item.id]
        );
        addedThemesIds.forEach((theme) => {
          const themeId = theme.id || theme.localId!;
          counter[themeId] = (counter[themeId] || 0) + 1;
        });

        const { shouldDisplayManualOther, shouldDisplayPersistedOther } =
          shouldDisplayManualOrPersistedOther(
            item,
            themesDraft,
            manualEditsMap[item.id],
            tagChangesMap[item.id]
          );

        if (shouldDisplayManualOther || shouldDisplayPersistedOther) {
          counter['Other'] = (counter['Other'] || 0) + 1;
        }
        return counter;
      },
      {} as { [themeId: string]: number }
    );

    setTagsCounter(themeCounter);
  }, [tagPageData, tagChangesMap, manualEditsMap, themesDraft]);

  return { tagsCounter, setTagsCounter };
};

export default useTagsCounter;
